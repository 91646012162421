/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import './header.css';

export const Header = () => {
        const [menuOpen, setMenuOpen] = useState(false);
        const [path, setPath] = useState('');
        const handleClick = () => {
                if (menuOpen) {
                        setMenuOpen(false);
                } else {
                        setMenuOpen(true);
                }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(() => {
                const pathName = window.location.pathname;
                // const host = window.location.host;
                setPath(pathName);
        })
        return (
                <div className='header'>

                        <div className={`nav-icon ${menuOpen ? "open" : ""}`} onClick={handleClick}>
                                <span></span>
                                <span></span>
                                <span></span>
                        </div>
                        <div className='nav-menu-items' style={menuOpen ? { display: 'flex' } : { display: 'none' }}>
                                <ul>
                                        <li>
                                                <a href={`${path}#home`} >Home</a>
                                        </li>
                                        {/* <li>
                                        <a href={`${path}#about`} >About</a>
                                </li> */}
                                        <li>
                                                <a href={`${path}#contactus`} >Join The Revolution</a>
                                        </li>
                                </ul>
                        </div>
                        <div className='nav-menu-items'>
                                <ul>
                                        <li>
                                                <a href={`${path}#home`} >Home</a>
                                        </li>
                                        {/*<li>
                                        <a href={`${path}#about`} >About</a>
                                </li> */}
                                        <li>
                                                <a href={`${path}#ourStory2`} >The Story of</a>
                                        </li>
                                        <li>
                                                <div className='brand-logo'>
                                                        <a href={`${path}#home`}>
                                                                <img src='../../assets/images/logo.png' alt='VER' />
                                                        </a>
                                                </div>
                                        </li>
                                        <li>
                                                <a rel="noreferrer" target={'_blank'} href={`https://www.instagram.com/cubavivaelron/`} >Join The Revolution</a>
                                        </li>
                                        <li>
                                                <a href={`${path}#contactus`} >
                                                        <img className="social-media-icon" src='../../assets/images/mail.png' alt='Contact Us' />
                                                </a>
                                                <a rel="noreferrer" target={'_blank'} href={`https://www.facebook.com/Bottled-Revolution-108195711938335`} >
                                                        <img className="social-media-icon" src='../../assets/images/fb.png' alt='VER Facebook' />
                                                </a>
                                                <a rel="noreferrer" target={'_blank'} href={` https://www.instagram.com/cubavivaelron/`} >
                                                        <img className="social-media-icon" src='../../assets/images/instagram.png' alt='VER Instagram' />
                                                </a>
                                        </li>
                                </ul>
                        </div>
                </div>
        )
}
