import React from 'react';
import {Wrapper} from './ui'


function App() {
  return (
    <Wrapper/>
  );
}

export default App;
