import styled, { keyframes } from 'styled-components';
import { slideInLeft, slideInRight } from 'react-animations'
const slineInLeftAnimation = keyframes`${slideInLeft}`;
const slineInRightAnimation = keyframes`${slideInRight}`;

export const SlideInleft = styled.div`
  animation: 1s ${slineInLeftAnimation};
  height: 100%;
  text-align: center;
  &>img {
    width: 250px;
    @media screen and (max-width: 1299px) and (min-width: 1200px) {
      width: 200px;
    }
    @media screen and (max-height: 650px) and (min-height: 600px) {
      width: 220px;
    }
  }
  
`;

export const SlideInRight = styled.div`
  animation: 1s ${slineInRightAnimation};
  color: #ffffff;
  text-align: left;
  padding-left: 50px;
  padding-top: 50px
`;