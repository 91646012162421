import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import './welcome-shutter.css';

export const WelcomeShutter  = (props) => {
        const [topShutter, setTopShutter] = useState(false);
        const [bottomShutter, setBottomShutter] = useState(false);
        const topContentProps = useSpring({
                opacity: topShutter ? 1 : 0,
                marginTop: topShutter ? 0 : -500,
        });
        const bottomContentProps = useSpring({
                opacity: bottomShutter ? 1 : 0,
                marginTop: bottomShutter ? 0 : 1000,
        })
        useEffect(() => {
                setTopShutter(true);
                setBottomShutter(true);
        }, [])
        setTimeout(() => {
        setTopShutter(false);
        setBottomShutter(false);
        props.shutterOpen(false);
        }, 1000);
        return (
        <div className='shutter-container'>
                {topShutter ? <animated.div className='shutter top-shutter' style={topContentProps}></animated.div> : <></> }
                {bottomShutter ? <animated.div className='shutter bottom-shutter' style={bottomContentProps}></animated.div> : <></> }
        </div>
        )
}
