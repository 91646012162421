import React, { useEffect, useState} from "react";
import { Col, Container, Row } from "react-bootstrap";
import './about.css'

export function About({stateReached}) {
    const [estable, setEstable] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isContVisible, setIsContVisible] = useState(false);

    useEffect(() => {
        if(document.getElementById('about')) {
            setEstable(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!estable])

    
    useEffect(() => {
        handleContainer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!isVisible, !isContVisible])

    if(estable) {
        const el = document.getElementById('about');
        const aboutRect = el.offsetTop;
        window.addEventListener('scroll', function() {
            const windowScroll = window.scrollY;
                if (aboutRect - windowScroll <= 50) {
                    setIsVisible(true);
                    stateReached(true);
                }
        })
    }
    const handleContainer = () => {
        if(isVisible) {
            setTimeout(() => {
                setIsContVisible(true);
            }, 4000);
        }
    }
    return(
        <div className="about-wrapper" id="about">
            {isVisible ? <Container fluid style={{zIndex: 111}}>
                <Row>
                    <Col md={{span: 7, order: 1}}>
                        <div style={{opacity: isContVisible ? 1: 0}} className="animation-div">
                        
                        <div className="about_inspired_nonanim">
                            <div className="rumInspired">THE RUM INSPIRED</div>
                            <h2>By the <span>Revolution</span></h2>
                        </div>
                        <div className="about-para">
                        In every <em>pueblo, poblano and ciudad</em> of Cuba, there was just one drink that everyone loved and craved.
It had no name nor did anyone know where it came from. It needed no name. Just <strong><em>El Ron</em></strong> it was called and loved by the revolucionarios and the others alike.<br/><br/>
It was the spirit of celebration and the spirit of commiseration.<br/><br/>
One day, the barrels ran dry and there was no more <strong><em>El Ron</em></strong>. Many looked for <strong><em>El Ron</em></strong>, and even the secret recipe for El Ron.<br/><br/>
But <strong><em>El Ron</em></strong> was lost for time.<br/><br/>
A time later, a <em>hombrè joven</em> finds a box filled with journals, filled with stories of the generations of Cubans. And in that, scribbled on the edges of one — the secret of making El Ron.<br/><br/>
So he tried once and then again, making <strong><em>El Ron</em></strong>, and realised he discovered the recipe of guzzle-worthy white gold. The Revolution lived again — the <em>hombrè</em> cheered. Viva El Ron!<br/><br/>
--- Revolutionary Brands, with its forever pursuit of the finer things that are remembered &amp; rejoiced, brought Viva <strong><em>El Ron</em></strong> to the world. <br/><br/>
The Revolution Lives Again - The <em>hombrè</em> cheered!
                        </div></div>
                        
                    </Col>
                    <Col md={{span: 5, order: 2}}>
                        {/* <div style={{opacity: isContVisible ? 0: 0}} className="animation-div about-us-bottle-section">
                            <img src="../../assets/images/bottle.png" style={{height: '100%', position: 'absolute', top: '0'}} alt=""/>
                        </div> */}
                    </Col>
                </Row>
            </Container>: ''}
            {isVisible ? <div className="animated-container" style={{zIndex: 11}}>
                <div className="about_revolution_text">
                    <img src="../assets/images/logo-2.png" alt="Viva El Ron"/>
                </div>
                <div className="about_sfr">
                    <div className="standsFor">Stands For</div>
                    <h2>Long Live Rum</h2>
                </div>
                <div className="about_inspired">
                    <div className="rumInspired">THE RUM INSPIRED</div>
                    <h2>By the Revolution</h2>
                </div>
                <div className="about_bottle">
                    <img src="../../assets/images/bottle.png" alt=""/>
                </div>
                
            </div> : ''}
        </div>
    )
}