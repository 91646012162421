import React, {useEffect, useState} from "react";
import { SlideInleft, SlideInRight } from './celebrate.style';
import { Container, Row, Col } from 'react-bootstrap';
import './celebrate.css';

export function Celebrate(props) {
    const [estable, setEstable] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        if(document.getElementById(props.id)) {
            setEstable(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!estable])
    if (estable) {
        const el = document.getElementById(props.id);
        const rect = el.offsetTop - el.offsetHeight/2;
        window.addEventListener('scroll', function () {
            const windowScroll = window.scrollY;
            if (rect - windowScroll <= 150) {
                setIsVisible(true);
            }
        });
    }
    return (
        <>
            <div className='celebrate-cuba-container' id={props.id}
            >
            <Container fluid style={{zIndex: 111, position: 'relative'}}>
                <Row className='align-items-center'>
                    <Col md={{span: 6, order: 1}}>
                        {isVisible ? <SlideInleft>
                            <img src="../../assets/images/updated_glass.svg" height={'100%'} alt="" />    
                        </SlideInleft> : ''}
                    </Col>
                    <Col md={{span: 6, order: 2}}>
                        {isVisible ? <SlideInRight>
                            <div>
                                <h2>Celebrate the <br/> Cuba Libre</h2>

                                <h4>Let's Start The Revolution <br/> with</h4>

                                <h6>1 Part Viva El Ron</h6>
                                <h6>3 Part Cola</h6>
                                <h6>A Squeeze of lime</h6>
                            </div>
                        </SlideInRight> : ''}
                    </Col>
                </Row>
            </Container>
            </div>
        </>
    );
}