import styled, { keyframes } from 'styled-components';
import { slideInLeft, slideInRight } from 'react-animations'
const slineInLeftAnimation = keyframes`${slideInLeft}`;
const slineInRightAnimation = keyframes`${slideInRight}`;

export const SlideInleft = styled.div`
  animation: 1s ${slineInLeftAnimation};
  height: 100%;
  text-align: center;
  &>img {
    height: 90%;
  }
`;

export const SlideInRight = styled.div`
  animation: 1s ${slineInRightAnimation};
  color: #ffffff;
  text-align: left;
  height: 100%;
`;