import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CToast, CToastClose, CToastBody, CToaster } from '@coreui/react';
import './entryPass.css';

export const Entry  = (props) => {
    const [toast, addToast] = useState(0);
    const toaster = useRef()
    const [isElegible, setIsElegible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [note, setNote] = useState(false);
    const countRef = useRef(isEditing);
    countRef.current = isEditing;
    function handleChange(event) {
        setIsEditing(true);
        setNote(false);
        const value = event.target.value;
        if (value.length === 4 && Number.isInteger(Number(value))) {
            getAge(Number(value))
        } else {
            setIsElegible(false);
        }
        
    }
    function getAge(year) {
        const today = new Date();
        const age = today.getFullYear() - year;
        if (age >= 18) {
            setIsElegible(true);
        }
    }
    function setToast() {
        addToast(getToast);
        setTimeout(() => {
            addToast(removeToast);
        }, 2000);
    }
    const getToast = (
        <CToast autohide={true} delay={2000}>
          <CToastBody>
            <div className='toast-body-inner'>You need to be 21 to enter the website</div>
          </CToastBody>
        </CToast>
    )
    const removeToast = (
        <></>
    )

    useEffect(() => {
        setTimeout(() => {
            if(!countRef.current) {
                showNote();
            }
        }, 10000);
    })
    const showNote = () => {
        setNote(true);
    }
    return (
    <div className='entry-container'>
        <Container fluid="md">
            <Row className='align-items-center'>
                <Col md={{span: 6, order: 2}} className='ver-hand-wrapper'>
                    <div className='ver-hand'>
                    </div>
                </Col>
                <Col md={{span: 6, order: 1}}>
                    <div className='welcome-note'>A Revolution has no age. but entering this website need one.</div>
                    <div style={{position: 'relative'}}>
                        {note ? <div className='note'>Please Enter Your Year of Birth</div> : ''}
                        <input id='age-year' onChange={handleChange} type="number" placeholder='yyyy' className='year-input' />
                    </div>
                    <div>
                        <button type='button' className='btn btn-submit' onClick={() => isElegible ? props.isElegible(true) : setToast()}>SUBMIT</button>
                    </div>
                </Col>
                
            </Row>
        </Container>
        <CToaster ref={toaster} push={toast} placement="top-center" />
    </div>
    )
}
