import styled, { keyframes } from 'styled-components';
import { slideInLeft, slideInRight } from 'react-animations'
const slineInLeftAnimation = keyframes`${slideInLeft}`;
const slineInRightAnimation = keyframes`${slideInRight}`;

export const SlideInleft = styled.h3`
  animation: 1s ${slineInLeftAnimation};
  color: #ffffff;
  font-size: 14rem;
  text-align: center;
  @media screen and (max-width: 767px) {
    font-size: 7rem;
  }
`;

export const SlideInRight = styled.h3`
  animation: 1s ${slineInRightAnimation};
  color: #ffffff;
  font-size: 14rem;
  text-align: center;
  @media screen and (max-width: 767px) {
    font-size: 7rem;
  }
`;