import React, { useEffect, useState } from 'react';
import { Entry, Home } from '../../components';
import { MobileViewComponent } from '../../mobile';
import { useSpring, animated } from 'react-spring'
import './wrapper.css';
import { BrowserView, MobileView } from 'react-device-detect';
import Modal from 'react-bootstrap/Modal';


export const Wrapper = () => {
    const [isElegible, setIsElegible] = useState(false);
    const [success, setSuccess] = useState(false);
    const fadeInProps = useSpring({ to: { opacity: 1 }, from: { opacity: 0.7 } });
    const checkElegible = (value) => {
        if (value) {
            setIsElegible(true)
        }
    }
    useEffect(() => {
        let status = 0;
        const params = new URLSearchParams(window.location.search);
        for (const [key, value] of params) {
            if (key === 'status') {
                status = Number(value);
            }
        }
        if (status === 200) {
            setSuccess(true);
        } else {
            setSuccess(false);
        }
    }, []);
    function hideModal() {
        const url = window.location.href;
        const newUrl = removeQueryParams(url);
        window.location.href = newUrl
        setSuccess(false)
    }
    function removeQueryParams(url) {
        return url.split("?")[0];
    }
    return (
        <>
            <BrowserView>
                {!isElegible ? <animated.div style={fadeInProps}><Entry isElegible={checkElegible} /></animated.div> :
                    <Home id="home"></Home>
                }
            </BrowserView>
            <MobileView>
                <MobileViewComponent />
            </MobileView>

            <Modal show={success} fullscreen={false} centered={true} onHide={() => hideModal()}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>Thank you for contacting us. We will get back to you soon.</Modal.Body>
            </Modal>
        </>
    )
}
