import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import { About, ContactUs, OurStory, OurStory2 } from '../../pages';
import { WelcomeShutter, JoinRevolution, Header, Celebrate, VideoParallax } from '../index';
import './home.css';

export const Home  = () => {
    
    const [shutterState, setShutterState] = useState(true);
    const [isHeaderVisible, setIsHeaderVisible] = useState(false);
    const [path, setPath] = useState('');
    const [scrollDown, setScrollDown] = useState(false);
    const transition = useTransition(isHeaderVisible, {
        from: { x: 0, y: -200, opacity: 0, transotion: '2s' },
        enter: { x: 0, y: 0, opacity: 1, transotion: '2s' }
    });
    const handleShutter = (val) => {
        setShutterState(val);
    }
    const handleReach = (val) => {
        setIsHeaderVisible(val)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const pathName = window.location.pathname;
        setPath(pathName);
        activateScrollDown();
    });
    function activateScrollDown() {
        setTimeout(() => {
            setScrollDown(true);
        }, 8000);
    }
    
        return (
        <div className='main-container'>
            {shutterState ? <WelcomeShutter shutterOpen={handleShutter}/> : 
            <>
                <div className='scroll-down'>
                    {scrollDown ? <a href="#spiritOfCourage"><span></span><span></span><span></span>Scroll Down</a> : ''}
                </div>
                {transition((style, item) => item ? <animated.div style={style}><Header/></animated.div>: '')}
                <JoinRevolution/>
                {/* <ImageContainer url="../../assets/images/slide-111.webp" text="Spirit of" subText="Courage"/> */}
                {/* <VideoContainer /> */}
                <VideoParallax id="spiritOfCourage" url={'../../assets/images/viva-vid.mp4'}/>

                {/* <ImagesParallax id={'spiritOfAction'} text="Spirit of" subText="Action" />

                <VideoParallax id="spiritOfRevolution" url={'../../assets/images/video_21.mp4'} text="Spirit of" subText="Revolution"/> */}

                <About stateReached={handleReach}/>
                {/* <OurStory id="ourStory"/> */}
                <OurStory2 id="ourStory2"/>
                <Celebrate id="celebrateCuba" />
                <ContactUs id="contactus" />
            </>
            }
        </div>
        )
}
