import React, {useEffect, useRef, useState} from "react";
import './video-parallax.css';

export function VideoParallax(props) {
    const [estable, setEstable] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [scrollText, setScrollText] = useState(false);
    const scrollDisable = useRef(true);
    useEffect(() => {
        if(document.getElementById('about')) {
            setEstable(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!estable]);
    useEffect(() => {
        scrollDisable.current = false;
    }, [])
    if (estable) {
        const el = document.getElementById(props.id);
        const rect = el.offsetTop + el.offsetHeight;
        window.addEventListener('scroll', function () {
            const windowScroll = window.scrollY;
            
            if (windowScroll >= rect - el.offsetHeight) {
                setIsVisible(true);
            }
            if (windowScroll > el.offsetTop - 10 && windowScroll < rect && scrollDisable.current === false) {
                scrollDisable.current = true;
                document.body.classList.add("no-scroll");
                setTimeout(() => {
                    document.body.classList.remove("no-scroll");
                    setScrollText(true);
                }, 5000);
            }
        });
    }
    return (
        <>
            <div className='video-parallax-container' id={props.id}
            >
                <div className='scroll-down'>
                    {scrollText ? <a href="#about"><span></span><span></span><span></span>Scroll Down</a> : ''}
                </div>
                <video autoPlay muted loop>
                    <source src={props.url} type="video/mp4"></source>
                </video>
                {/* {isVisible ? <div className="parallax-text" style={{width: '100%', paddingRight: '10rem'}}>
                    <SlideInleft>{props.text}</SlideInleft>
                    <SlideInRight>{props.subText}</SlideInRight>
                </div> : ''} */}
            </div>
        </>
    );
}