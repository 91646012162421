import React, { useState, useEffect } from 'react';
import './mobile-view.css';
export const MobileViewComponent = () => {
    const [isVisible, setIsVisible] = useState(false);
    const openMenu = () => {
        setIsVisible(true)
    }
    const closeMenu = () => {
        setIsVisible(false)
    }
	const [captcha, setCaptcha] = useState('');
    const [values, setValues] = useState({ 
        username: '', email: '', phone: '', desc: '', captcha: ''
    });
    const set = name => {
        return ({ target: { value } }) => {
          setValues(oldValues => ({...oldValues, [name]: value }));
        }
    };
	useEffect(() => {
        createCaptcha(6, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
	function createCaptcha(length, chars) {
        let result = '';
        for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
        setCaptcha(result);
    }
    function handleClick(event) {
        if(values.captcha === captcha) {
            return true;
        } else {
            event.preventDefault();
            alert('Please Enter Valid Captcha');
            return false;
        }
    }
    return(
        <>
            <div className="main_container">
			<header className="header" id="home">
				<nav>
					<a href="#home">
						<img src="../assets/images/main_logo.png" alt="hero_bg" />
					</a>
					{isVisible ? <ul className="nav_menu_links">
						<li><a href="#home">Home</a></li>
						{/* <li><a href="#about">About</a></li> */}
						<li><a href="#contact">Contact Us</a></li>
					</ul> : <></>}
					{!isVisible ? 
                    <img onClick={openMenu}
                    className="menu_icon"
                    src="../assets/images/menu.svg"
                    alt="menu_icon"
                    />:
                    <img onClick={closeMenu}
                    className="menu_icon"
                    src="../assets/images/close-outline.svg"
                    alt="menu_icon"
                />}
				</nav>
				<div className="hero_container">
					<div className="hero">
						<div className="content">
							<p className="heading">THE RUM INSPIRED BY THE REVOLUTION</p>
							<p className="find_more">
								Find out More
								<a href="#about">
									<img src="../assets/images/arrow.png" alt="arrow_img" />
								</a>
							</p>
						</div>
						<div className="hero_img">
							<img src="../assets/images/hero_img.png" alt="hero_img" />
						</div>
					</div>
				</div>
			</header>
			<section className="about" id="about">
				<div className="hero_container">
					<div className="hero">
						<div className="content">
							<p className="heading"><span> VIVA EL RON</span> TRANSLATES TO</p>
							<p className="sub_heading">"LONG LIVE REVOLUTION"</p>
						</div>
						<div className="para">
							<p>
								The actual recipe has been developed in more than 150 years of
								activity, and use a special blend of silver ron. A
								<b> “Selección de 15 Maestros Roneros” </b> supervise all the
								phases of production, from the harvest to the distillation till
								the blending - creating a fresh, soft, smooth rum, with a
								complex bouquet of scents and flavours that reminds you the
								product is from Cuba, which is protected by the
								<b> “Denominación de Origen Protegida Cuba”</b>
							</p>
						</div>
					</div>
				</div>
			</section>
			<section className="about contact_us" id="contact">
				<div className="hero_container">
					<div className="hero">
						<p className="heading">Contact Us</p>
						<div className="form">
							<div className="wrapper">
							<form onSubmit={handleClick} method="post" action="http://vivaelron.com/mail/send.php" className="" id="contactusform" encType= "multipart/form-data">
								<div className="">
									<input type="text" value={values.username} onChange={set('username')}
									name="username" placeholder="Name" required />
								</div>
								<div className="">
									<input type="email" placeholder="Email" value={values.email}
									name="email" onChange={set('email')} required />
								</div>
								<div className="">
									<input maxLength={10} onChange={set('phone')} required type={"tel"} placeholder="Phone" value={values.phone}
									name="phone" />
								</div>
								<div className="">
									<textarea onChange={set('desc')} placeholder="Description" rows={'6'} value={values.desc}
									name="desc"></textarea>
								</div>
								<div className="captcha-wrapper">
									<div className="captcha">{captcha}</div>
									<input required value={values.captcha} onChange={set('captcha')} type={"input"} placeholder="Enter the text"
									name="captcha" />
								</div>
								<div className="text-center">
								<button type="submit" name="signUp" className="btn btn-primary">
									SUBMIT
								</button>
								</div>
							</form>
							</div>
							<img className="bg" src="../assets/images/red_bg.png" alt="red_bg" />
						</div>
					</div>
				</div>
			</section>
		</div>
        </>
    )
}