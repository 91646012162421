import React, {useEffect, useState} from "react";
import { SlideInleft, SlideInRight } from './contactus.style';
import { Container, Row, Col } from 'react-bootstrap';

import './contact-us.css';

export function ContactUs(props) {
    const [estable, setEstable] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [captcha, setCaptcha] = useState('');
    const [values, setValues] = useState({ 
        username: '', email: '', phone: '', desc: '', captcha: ''
    });
    const set = name => {
        return ({ target: { value } }) => {
          setValues(oldValues => ({...oldValues, [name]: value }));
        }
    };
    useEffect(() => {
        createCaptcha(6, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
        if(document.getElementById(props.id)) {
            setEstable(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!estable])
    if (estable) {
        const el = document.getElementById(props.id);
        const rect = el.offsetTop - el.offsetHeight/2;
        window.addEventListener('scroll', function () {
            const windowScroll = window.scrollY;
            if (rect - windowScroll <= 150) {
                setIsVisible(true);
            }
        });
    }
    function createCaptcha(length, chars) {
        let result = '';
        for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
        setCaptcha(result);
    }
    function handleClick(event) {
        if(values.captcha === captcha) {
            return true;
        } else {
            event.preventDefault();
            alert('Please Enter Valid Captcha');
            return false;
        }
    }
    return (
        <>
            <div className='contact-us-container' id={props.id}
            >
            <Container fluid style={{zIndex: 111, position: 'relative'}}>
                <Row className='align-items-center'>
                    <Col md={{span: 6, order: 1}}>
                        {isVisible ? <SlideInleft>
                            <div className="contactus-images">
                                <h4>Join the <br/> Revolution</h4>
                                <div className="glass_image">
                                    <img src="../../assets/images/updated_glass.svg" alt="" />
                                </div>
                                <div className="contactus_bottle">
                                    <img src="../../assets/images/bottle.png" alt="" />
                                </div>
                            </div>
                        </SlideInleft> : ''}
                    </Col>
                    <Col md={{span: 6, order: 2}}>
                        {isVisible ? <SlideInRight>
                            <div className="contact-us-form">
                                <form onSubmit={handleClick} method="post" action="https://vivaelron.com/mail/send.php" className="" id="contactusform" encType= "multipart/form-data">
                                    <div className="form-group">
                                        <input type="text" className="form-control" value={values.username} onChange={set('username')}
                                        name="username" placeholder="Name" required />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control" placeholder="Email" value={values.email}
                                        name="email" onChange={set('email')} required />
                                    </div>
                                    <div className="form-group">
                                        <input maxLength={10} onChange={set('phone')} required type={"tel"} className="form-control" placeholder="Phone" value={values.phone}
                                        name="phone" />
                                    </div>
                                    <div className="form-group">
                                        <textarea onChange={set('desc')} className="form-control" placeholder="Description" rows={'6'} value={values.desc}
                                        name="desc"></textarea>
                                    </div>
                                    <div className=" form-group captcha-wrapper">
                                        <div className="captcha">{captcha}</div>
                                        <input required value={values.captcha} onChange={set('captcha')} type={"input"} className="form-control" placeholder="Enter the text"
                                        name="captcha" />
                                    </div>
                                    <div className="text-center">
                                    <button type="submit" name="signUp" className="btn btn-primary">
                                        SUBMIT
                                    </button>
                                    </div>
                                </form>
                            </div>
                        </SlideInRight> : ''}
                    </Col>
                </Row>
            </Container>
            </div>
        </>
    );
}