import React, { useEffect, useState } from 'react';
import './join-revolution.css';

export const JoinRevolution  = () => {
    const [textVisible, setTextVisible] = useState(false);
    const [fadeFirstProp, setFadeFirstProp] = useState({
        fade: 'fade-out'
    })
    const [fadeSecondProp, setFadeSecondProp] = useState({
        fade: 'fade-out'
    })
    setTimeout(() => {
        setTextVisible(true);
    }, 3000);
    useEffect(() =>{
        setTimeout(() => {
            if(textVisible) {
                setFadeFirstProp({fade: 'fade-in'})
            }
        }, 1000);
        setTimeout(() => {
            if(textVisible) {
                setFadeSecondProp({fade: 'fade-in'});
            }
        }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textVisible])
        return (
        <>
            <div className='break-glass-video-wrapper' id='home'>
                <video className='break-glass-video' muted="" autoPlay>
                    <source src="../../assets/images/break_glass.mp4" type="video/mp4"></source>
                </video>
            </div>
            {/* {textVisible?<div className='join-revolution-text-wrapper'>
                <div className={'rev-first'}>JOIN</div>
                <div className={'rev-second '+fadeFirstProp.fade}>THE</div>
                <div className={'rev-third ' + fadeSecondProp.fade}>REVOLUTION</div>
            </div>: <></>} */}
        </>
        )
}
