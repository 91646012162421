import React, {useEffect, useState} from "react";
import { SlideInleft, SlideInRight } from './ourstory.style';
import { Container, Row, Col } from 'react-bootstrap';
import './our-story.css';

export function OurStory2(props) {
    const [estable, setEstable] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        if(document.getElementById(props.id)) {
            setEstable(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!estable])
    if (estable) {
        const el = document.getElementById(props.id);
        const rect = el.offsetTop - el.offsetHeight/2;
        window.addEventListener('scroll', function () {
            const windowScroll = window.scrollY;
            if (rect - windowScroll <= 150) {
                setIsVisible(true);
            }
        });
    }
    return (
        <>
            <div className='our-story-container ourstory-container-2' id={props.id}
            >
            <Container fluid style={{zIndex: 111, position: 'relative'}}>
                <Row className='align-items-center'>
                    <Col md={{span: 6, order: 1}}>
                        {isVisible ? <SlideInleft>
                            <h2 className="ourstory-title">FROM THE ISLAND OF CUBA</h2>
                            <div className="ourstory-para">
                            Everything starts with the harvest of the best sugarcane, in the green valleys of Cuba. <br/><br/>
The actual recipe has been developed in more than 150 years of activity, and use a special blend of silver ron.<br/><br/>

A "Selección de 15 Maestros Roneros" supervise all the phases of production, from the harvest to the distillation till the blending - creating a fresh, soft, smooth rum, with a complex bouquet of scents and flavours that reminds you the product is from Cuba, which is protected by the
<br/>"Denominación de Origen Protegida Cuba"
                            </div>
                        </SlideInleft> : ''}
                    </Col>
                    <Col md={{span: 6, order: 2}}>
                        {isVisible ? <SlideInRight>
                            <div className="ourstory-bottle">
                                <img className="os-bottle1" src="../../assets/images/bottle.png" alt="ver bottle" /> 
                                <img className="os-bottle2" src="../../assets/images/bottle.png" alt="ver bottle" /> 
                                <img className="os-bottle3" src="../../assets/images/bottle.png" alt="ver bottle" /> 
                            </div>
                        </SlideInRight> : ''}
                    </Col>
                </Row>
            </Container>
            </div>
        </>
    );
}